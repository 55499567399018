.tooltip-container {
    position: relative;
    //width: 400px;

    .line {
        height: 1px;
        width: 100%;
        background: white;
    }
}

.tooltip-text {
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    //left: 50%;
    //width: 400px;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltip-text.show {
    opacity: 1;
}

.tooltip-trigger {
    position: relative;
    z-index: 0;
}


.tooltip-icsaApy {
    font-size: 8px;
    width: 300px;
    left: -100px;
}
.tooltip-shareValue {
    font-size: 8px;
    width: 200px;
    left: 0px;
}