@import "./SiteWide/bootstrap-4.6.2-dist/css/bootstrap.css";
@import "./Components/DragAndDrop.scss";
@import "./Components/Marquee.scss";


@import "./SiteWide/content-styling.scss";
@import "./SiteWide/text-styling.scss";

@import "./Components/SiteHeader.scss";
@import "./Components/SiteFooter.scss";
@import "./Components/MainContent.scss";
@import "./Components/LeftNavigationBar.scss";

@import "./Components/Tooltips.scss";
@import "./Pages/main.scss";
@import "./Components/Modal.scss";

@import "./SiteWide/responsiveness.scss";

.toggle-theme{

    position: absolute;

    z-index: 9000;
    top: 200px;
}