//@import "./text-styling.scss";

$main-content-min-height: 1000px;
$left-nav-width: 300px;

/*
$scroll-bar-width: 20px;
$scroll-bar-color: rgba(var(--background-color-0),0.3);
$scroll-bar-track: rgba(var(--background-color-0),0.3);
$scroll-bar-thumb: rgba(var(--background-color-2),0.6);
$scroll-bar-thumb-hover: var(--background-color-3);
$scroll-bar-corner: var(--background-color-3);
*/

.App, .App-header, .main-wrapper {
    overflow-x: hidden;

    scrollbar-width: var(--scroll-bar-width);
    scrollbar-color: var(--scroll-bar-color);
    /* Set the width of the scrollbar */
    &::-webkit-scrollbar {
        background-color: var(--scroll-bar-color);
        width: var(--scroll-bar-width);
    }

    /* Set the background color of the scrollbar track */
    &::-webkit-scrollbar-track {
        background-color: var(--scroll-bar-track);
    }
    
    /* Set the color and border radius of the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-bar-thumb);
        border-radius: 5px;
    }
    &::-moz-scrollbar-thumb {
        background-color: var(--scroll-bar-thumb);
        border-radius: 5px;
    }

    /* Set the color of the scrollbar thumb on hover */
    &::-webkit-scrollbar-thumb:hover, ::-moz-scrollbar-thumb:hover {
        background-color: var(--scroll-bar-thumb-hover);
    }
    
    /* Set the border radius of the top and bottom of the scrollbar track */
    &::-webkit-scrollbar-track-piece:start, .custom-scrollbar::-webkit-scrollbar-track-piece:end {
        border-radius: 5px;
    }

    
}

.height-max { height: 100%; }
.spacer {
    padding-top: 10px;

    &-t10 {padding-top: 10px}
    &-t20 {padding-top: 20px}
    &-t30 {padding-top: 30px}
    &-t40 {padding-top: 40px}
    &-t50 {padding-top: 50px}
}

.padding {
    padding: 10px;

    &-r5 { padding-right: 5px }
    &-r10 { padding-right: 10px }

    &-t10 {padding-top: 10px}
    &-t20 {padding-top: 20px}
    &-t30 {padding-top: 30px}
    &-t40 {padding-top: 40px}
    &-t50 {padding-top: 50px}

    &-b10 {padding-bottom: 10px}
    &-b20 {padding-bottom: 20px}
    &-b30 {padding-bottom: 30px}
    &-b40 {padding-bottom: 40px}
    &-b50 {padding-bottom: 50px}
}
.d-inline {
    display: inline-block;
}

.input-container {
    padding: 10px;
    width: 100%;
}

.input-control {
    width: 90%;
    font-size: 10px;
    border-radius: 5px;
    padding-left: 10px;
}

.button {
    user-select: none;
    cursor:pointer;
}