.drag-and-drop-container {
    transform: translateX(0px);
    font-size: 10px;

    .drag-and-drop-item {
        //background-color: white;
        user-select: none;
        border: 1px solid #ddd;
        cursor: pointer;
        margin-bottom: 5px;
        padding: 10px;
        height: auto;
        transition: all 0.2s ease-in-out;
      }
      
      .drag-and-drop-item.dragging {
        opacity: 0.5;
      }
      
      .drag-and-drop-item.over {
        //border: 1px dashed #333;
      }
      
      .drag-and-drop-area {
        background-color: #f7f7f7;
        border: 1px solid #ccc;
        padding: 10px;
        transition: all 0.2s ease-in-out;
      }
      
      .drag-and-drop-area.dragging {
        opacity: 0.5;
        cursor: move;
      }
      
      .drag-and-drop-area.over {
        border: 1px dashed #333;
      }
      .empty-row {
        height: 20px;
      }
      .animate-items {
        transition: transform 0.2s ease-in-out;
      }
      .animate-up {
        transform: translateY(-25px);
      }
      .animate-down {
        transform: translateY(25px);
      }
      .animate-left {
        transform: translateX(-10px);
        background: rgba(0,0,0,.1);
      }
      .animate-right {
        transform: translateX(10px);
        background: rgba(0,0,0,.1);
      }
}