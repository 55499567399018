@media (max-width: 768px) {
    .mobile-only {
      display: block;
    }
  }
@media (min-width: 769px) {
    .mobile-only {
      display: none;
    }
}

@media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
}

.main-wrapper {
  min-height: 100vh;
  height: 100vh;
}

.pos-rel { position: relative }