.marquee {
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    color: rgb(200, 200, 200);
}
  
.marquee__static {
  position: absolute;
  left: 0px;
  bottom: 0px;
  background: black;
  z-index: 100;
  padding-right: 5px;
  font-size: 10px;
  height: 25px;
  padding-top: 5px;
  border-right: teal 1px dotted;
  img {
    height: 15px;
    padding-bottom: 2px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
.marquee__content {
  font-family: "Orbitron", "Courier New", monospace;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
  img {
    height: 15px;
    padding-bottom: 2px;
    padding-right: 5px;
    padding-left: 15px;
  }

  /* for desktop devices */
  @media (min-width: 768px) {
    animation: marquee 35s linear infinite;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}