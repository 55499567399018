$text: rgba(220, 220, 220, 1);
$muted-text: rgba(180,180,180,1);

$background-color-0: black;
$background-color-1: rgba(4,12,28,1);
$background-color-2: rgba(16,30,56,1);
$background-color-3: rgba(14,36,89,1);
$background-color-4: rgba(20,56,128,1);
$background-color-5: rgba(31,85,194,1);



$danger-color-0: rgba(134, 1, 1,1);
$danger-color-1: rgb(194, 0, 0);

$ticker-color-green: lightgreen;
$ticker-color-yellow: yellow;
$ticker-color-red: red;
$ticker-color-muted: gray;

$hex-color-0: rgba(255,210,21,1);
$hex-color-1: rgba(217,24,166,1);
$hex-color-2: rgba(219,23,266,1);

$hex-gradient: -webkit-linear-gradient(75deg, $hex-color-0 20%, $hex-color-1 60%, $hex-color-2 80%);

$white-color-2: rgba(203,220,251,1);

//$linear-background-0: linear-gradient(270deg,$background-color-4 -10%,  $background-color-2 0%, darken($background-color-1,5%) 100%);
$linear-background-0: linear-gradient(270deg,$background-color-4 -60%,  $background-color-2 30%, darken($background-color-1,5%) 100%);
$linear-background-1: linear-gradient(125deg,$background-color-2 0%, $background-color-1 65%,$background-color-2 65%, $background-color-1 90%);
$linear-background-2: linear-gradient(270deg,rgba(203,220,251,1) 0,rgba(62,62,128,1) 5%,rgba(0,0,0,1) 40%);

$radial-background-0: radial-gradient(circle,rgba(203,220,251,1) 0,rgba(62,62,128,1) 73%,rgba(0,0,0,1) 100%);
$radial-background-1: radial-gradient(circle,rgba(203,220,251,.3) 0,rgba(62,62,128,.3) 73%,rgba(0,0,0,.3) 100%);

.main-wrapper {
    //color: $text;
    //background: $linear-background-1;
    color: var(--text-color);
    background: var(--linear-background-1);

    &.theme-4 {
        .drag-and-drop-item{
            &.selected {
                background: rgba(0, 234, 255, 0.335)!important;
            }
        }
        .site-footer {
            background: rgba(0, 27, 31, 0.923)!important;
        }
        .background-image-theme4 {
            position: fixed;
            left: -20px;
            bottom: -20px;
            width: 100%;
            height: 1000px;
            background: black;
            background-repeat: repeat-x;
            background-size: cover;
            background-position: bottom;
        }
        .card {
            // background: rgba(0,0,0,.4);
            background: linear-gradient(to bottom, rgba(1, 1, 1, 0.1), rgba(1, 1, 1, 0.7) 20%, rgba(88, 230, 255, 0.15))!important;
            background-repeat: repeat-x;
            background-size: cover;
            background-position: bottom;
            border: rgba(131, 129, 252, 0.39) solid 1px!important;
            box-shadow: 3px 3px 3px 3px rgba(88, 230, 255, 0.15)!important;

            .card-jumbo-content {
                color: rgb(255, 255, 255)!important;
                text-shadow: 2px 2px 4px rgba(27, 225, 239, 0.964)!important;
                .sub {
                    color: var(--text-color);
                    text-shadow: none;
                }
            }

            .sorted-column {
                background-color: rgba(7, 251, 255, 0.257)!important;
            }
            th{
               background: linear-gradient(to bottom, rgba(1, 1, 1, 0.1), rgba(1, 1, 1, 0.7) 20%, rgba(88, 230, 255, 0.15))!important;
            }
        }
        .left-nav-bar {
            background:linear-gradient(to bottom, rgba(1, 1, 1, 1), rgba(1, 1, 1, 1) 10%, rgb(0, 46, 54))!important;
        }
    }
    
}

.w-{
    &20 { width: 20px }
}
.p-{
    &10 { padding: 5px 10px 5px 10px }
}
.t-{
    &muted {
        //color: $muted-text;
        color: var(--muted-text-color);
    }
    &ar { text-align: right; }
    &al { text-align: left; }
    &ac { text-align: center; }
}