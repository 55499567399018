@import "../SiteWide/text-styling.scss";

.site-footer {
    user-select: none;
    display: block;
    height: 200px;
    min-height: 175px;
    width: 100%;
    min-width: 100vw;
    background-color: var(--background-color-1);
    margin-top: auto;
    position: relative;

    .disclaimer {
        position: absolute;
        bottom: 40px;
        padding-right: 20px;
        font-size: 9px;
        display:inline-block;
        text-align: center;
        color: var(--muted-text-color);
        width: 100%;
    }
    .site-footer-logo {
        //padding-left: 0px;
        //padding-top: 5px;
        font-size: 20px;
        //width: 300px;
        background: -webkit-linear-gradient(75deg, var(--hex-color-0) 35%, var(--hex-color-1) 60%, var(--hex-color-2) 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-weight: bold;
        padding-bottom: 10px;
        
        span{
            -webkit-background-clip:initial;
            -webkit-text-fill-color: white;
            font-size: 12px;
        }

    }

    .footer-buttons{
        width: 100%;
        padding-bottom: 20px;
    }

    .twitter-image, .donate-image, .patreon-image {
        //position: absolute;
        //bottom: 15px;
        //right: 35px;
        //height: 20px;
        display: inline-block;
        width: 40px;
        img {
            width: 100%;
            height: 100%;
            margin-top: -9px;
        }
        svg {
            font-size: 28px;
            padding-bottom: 3px;
        }
        
        transition: filter 0.2s, transform 0.2s;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
            cursor: pointer;
            color: gold;
            animation: rocking 1s ease-in-out infinite;
        }

        small {
            display: block;
            position: relative;
            margin-top: -5px;
            margin-left: -5px;
            font-size: 10px;
        }
    }
}


@keyframes gradient-scroll {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
}