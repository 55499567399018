.modal {
    position: fixed;
    top: 0px;
    left: 1.6%;
    width: 95%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    z-index: 9000;
    user-select: none;
  }

  .modal-title {
    text-align: center;
    border-bottom: 1px var(--muted-text-color) solid;
  }
  
  .modal.open {
    visibility: visible;
    opacity: 1;
  }
  
  .modal-content {
    background-color: var(--background-color-3);
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .subheader {
    font-weight: bold;
    text-decoration: underline;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-color);
  }
  
  .modal-confirm {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #008cba;
    color: var(--text-color);
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;
  }