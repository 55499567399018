//@import "../SiteWide/text-styling.scss";
//@import "../SiteWide/content-styling.scss";

.main-content-container {
    vertical-align: top;
    display: inline-block;
    min-height: 100%;
    padding-top: 16px;
    padding-bottom: 75px;
    width: calc( 100% - var(--left-nav-width));
    min-width: calc( 100% - var(--left-nav-width));
    user-select: none;
    overflow: hidden;
    flex: 1;
    
    .tooltiptext {
        visibility: hidden;
        //margin-left: -80px;
        margin-top: 15px;
        font-size: 10px;
        width: 100px;
        max-width:100px;
        background-color: var(--background-color-3-alt0);
        box-shadow: 0 0 2px 1px var(--background-color-1);
        color: var(--text-color-highlighted);
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 6000;
    }
    
    .custom-tooltip:hover .tooltiptext {
        visibility: visible;
    }

    @media (min-width: 769px) {
        display: inline-block;
        width: calc( 100% - var(--left-nav-width));
        min-width: calc( 100% - var(--left-nav-width));
    }

    @media (max-width: 768px) {
        display: inline-block;
        width: 100%;
        min-width: 100%;
    }

    .card, card-body {
        border: 1px solid var(--background-color-1);
        //background-color: var(--background-color-2-alt0);
        background: var(--background-card-color-0);
    }

    .card {
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
    }
    .mining-status-disclaimer {
        display: block;
        color: var(--muted-text-color);
        font-size: 8px;
        width: 100%;
        text-align: center;
        small {
            display: inline;
        }

    }

    .card-jumbo {
        font-size: 40px;
        text-align: center;
        overflow-x: hidden;
        white-space: nowrap;
        position: relative;
        
        color: calc(var(--text-color-highlighted) * 75%);    
        .sub{
            font-size: 14px;
            color: calc(var(--text-color-highlighted) * 75%);   
        }
        .jumbo-loader{
            display: inline-block;
            position: absolute;
            top: 32px;
            margin-left: -5px;
            overflow: hidden;
            width: 15px;
            height: 37px;
            padding-bottom: 22px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .card-jumbo-content {
        font-size: 30px;
        overflow-x: hidden;
        white-space: nowrap;
        color: var(--text-color-highlighted);
    }
    .small-header {
        font-size: 13px;
        text-align: center;
    }

    .main-page-header{
        padding-bottom: 20px;
        padding-left: 50px;
        font-size: 24px;
    }

    .loading-liquid{
        width: 100%;
        text-align: center;
    }
    .liquid-chain {
        width: 100%;
        .liquid-chain-title {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 9px;
            color: var(--muted-text-color);
            border-bottom: 1px solid var(--background-color-1);
        }
        .liquid-chain-balances{
            width: 100%;
            margin-left: 1px;
            text-align: center;
            background: rgba(0,0,0,.2);
            padding-bottom: 25px;
        }
    }
    .liquid-wallet-balance {
        position: relative;
        padding-top: 10px;

        .liquid-wallet-title { 
            position: absolute;
            margin-top: -24px;
            margin-left: calc( 50% - 48px );
            font-size: 14px;
        }

         img {
            width: 15px;
            height: 15px;
         }
         span {
            font-size: 11px;
            padding-left: 10px;
         }
    }
    .no-provider-liquid{
        text-align: center;
        font-size: 10px;
    }
    .liquid-wallet-balance-token {
        position: relative;
        white-space: nowrap;
    }

    .miner-list-card {
        //height: auto;
        width: 100%;
        height: 400px;
        overflow-y: hidden;
        
        .miners-title {
            text-align: center;
            padding-top: 13px;
            padding-bottom: 10px;
            font-size: 16px;
            user-select: none;
            height: 50px;
            position: relative;

            .miner-list-info {
                position: absolute;
                font-size: 9px;
                left: calc( 50% - 43px );
                color: var(--muted-text-color);
                margin-top: -3px;
            }
            .miner-list-info-2 {
                position: absolute;
                font-size: 7px;
                left: calc( 50% - 25px );
                top: 5px;
                color: var(--muted-text-color);
            }

            .export-to-excel{
                position: absolute;
                left: 10px;
                top: 0px;
                font-size: 24px;
                height: 25px;
                width: 25px;
                padding: 5px;
                cursor: pointer;

                transition: color 0.3s;
                cursor:pointer;
                &:hover {
                    color: var(--text-color-highlighted);
                }
            }

            .move-to-next-section-button {
                position: absolute;

                right: 20px;
                top: 0px;
                font-size: 24px;
                height: 25px;
                width: 25px;
                padding: 5px;
                cursor: pointer;

                transition: color 0.3s;
                cursor:pointer;
                &:hover {
                    color: var(--text-color-highlighted);
                }
            }
        }

        .miners-card-body {
            position: absolute;
            left: 0px;
            width: 100%;
            top: 95px;

            .card{
                box-shadow: none;
                //var(--miner-card-body): 300px;
                height: var(--miner-card-body);
                max-height: calc( var(--miner-card-body) - 35px );
                table {
                    height: auto;//var(--miner-card-body);
                    max-height: var(--miner-card-body);
                }
    
                overflow-y: auto;

                scrollbar-width: var(--scroll-bar-width);
                scrollbar-color: calc( var(--scroll-bar-color) * 1.3);
                /* Set the width of the scrollbar */
                &::-webkit-scrollbar {
                    background-color: var(--scroll-bar-color);
                    width: var(--scroll-bar-width);
                }
            
                /* Set the background color of the scrollbar track */
                &::-webkit-scrollbar-track {
                    background-color: var(--scroll-bar-track);
                }
                
                /* Set the color and border radius of the scrollbar thumb */
                &::-webkit-scrollbar-thumb {
                    background-color: var(--scroll-bar-thumb-inner);//calc( var(--scroll-bar-thumb) * 1.3);
                    border-radius: 5px;
                }
                &::-moz-scrollbar-thumb {
                    background-color: var(--scroll-bar-thumb-inner);//calc( var(--scroll-bar-thumb) * 1.3);
                    border-radius: 5px;
                }
            
                /* Set the color of the scrollbar thumb on hover */
                &::-webkit-scrollbar-thumb:hover, ::-moz-scrollbar-thumb:hover {
                    background-color: var(--scroll-bar-thumb-hover);
                }
                
                /* Set the border radius of the top and bottom of the scrollbar track */
                &::-webkit-scrollbar-track-piece:start, .custom-scrollbar::-webkit-scrollbar-track-piece:end {
                    border-radius: 5px;
                }
            }

        }

        // miners title
        .title-fixed {
            position: fixed;
            width: 100%;
        }
        .title-card {
            position: absolute;
            width: 100%;
            z-index: 3500;
            left: 0;
            top: -5px;
            box-shadow: none;
            thead {
                background-color: var(--background-color-1-alt0);
                box-shadow: inset 0 -10px 10px -10px var(--background-color-4); // Miner Title Box Shadow
                tr {
                    text-align: center;
                    th{
                        position: relative;
                        padding-bottom: 20px;
                        padding-top: 10px;
                        font-size: 9px;
                        transition: box-shadow 0.15s, background-color 0.15s;
                        &.sorted-column {
                            background-color: var(--highlighted-color-0);
                            box-shadow: inset 0 -10px 10px -10px var(--background-color-4); // Miner Title Box Shadow
                        }
    
                        &:hover {
                            cursor: pointer;
                            background-color: var(--highlighted-color-1);
                            box-shadow: inset 0 -10px 10px -10px var(--background-color-4); // Miner Title Box Shadow
                        }
    
                        .sort-icon{
                            position: absolute;
                            margin-left: calc( 50% - 5px );
                            margin-top: -1px;
                        }
                    }
                    th:nth-child(1){ // chain
                        width: 30px;
                    }
                    th:nth-child(2){ // address
                        width: 70px;
                    }
                    th:nth-child(3){ // type
                        width: 70px;
                    }
                    th:nth-child(4){ // start
                        width: 70px;
                    }
                    th:nth-child(5){ // end
                        width: 70px;
                    }
                    th:nth-child(6){ // shares
                        width: 75px;
                        padding-right: 10px;
                    }
                    th:nth-child(7){ // value
                        width: 75px;
                        padding-right: 20px;
                    }
    
    
    
                    th:nth-child(8){ // hex burned
                        width: 70px;
                    }
                    th:nth-child(9){ // hex mined
                        width: 80px;
                    }
                    th:nth-child(10){ // hdrn mint
                        width: 130px;
                    }
                    th:nth-child(11){ // future mint
                        width: 75px;
                        overflow: hidden;
                        //white-space: nowrap;
                        padding-bottom: 10px;
                        padding-top: 0px;
                        height: 20px;
                    }
                    th:nth-child(12){ // apy
                        width: 75px;
                        text-align: right;
                        padding-right: 20px;
                    }
                }
            }
        }

        .miners-footer-card {
            position: absolute;
            width: 100%;
            z-index: 3500;
            left: 0;
            bottom: 0px;
            box-shadow: none;

            // This is actually the footer
            thead {
                background-color: var(--background-color-1-alt0);
                //box-shadow: inset 0 -10px 10px -10px var(--background-color-4); // Miner Title Box Shadow
                tr {
                    text-align: center;
                    th{
                        position: relative;
                        padding-bottom: 10px;
                        padding-top: 10px;
                        padding-right: 20px;
                        font-size: 11px;
                        transition: box-shadow 0.15s, background-color 0.15s;
                        &.sorted-column {
                            background-color: var(--highlighted-color-0);
                            box-shadow: inset 0 -10px 10px -10px calc(var(--background-color-4) * 1.1); // Miner Title Box Shadow
                        }
    
                        &:hover {
                            cursor: pointer;
                            background-color: var(--highlighted-color-1);
                            box-shadow: inset 0 -10px 10px -10px calc(var(--background-color-4) * 1.2); // Miner Title Box Shadow
                        }
    
                        .sort-icon{
                            position: absolute;
                            margin-left: calc( 50% - 5px );
                            margin-top: -1px;
                        }
                    }
                    th:nth-child(1){ // chain
                        width: 30px;
                    }
                    th:nth-child(2){ // address
                        width: 70px;
                    }
                    th:nth-child(3){ // type
                        width: 70px;
                    }
                    th:nth-child(4){ // start
                        width: 70px;
                    }
                    th:nth-child(5){ // end
                        width: 70px;
                    }
                    th:nth-child(6){ // shares
                        width: 75px;
                        text-align: right;
                        padding-right: 10px;
                        @media (max-width: 767px) {
                            padding-right: 10px;   
                        }
                    }
                    th:nth-child(7){ // value
                        width: 75px;
                        text-align: right;
                        padding-right: 25px;
                    }
    
    
    
                    th:nth-child(8){ // hex burned
                        width: 70px;
                        text-align: right;
                        padding-right:10px;
                        @media (max-width: 767px) {
                            padding-right: 0px;   
                        }
                    }
                    th:nth-child(9){ // hex mined
                        width: 80px;
                        text-align: right;
                        padding-right: 10px;
                        @media (max-width: 767px) {
                            padding-right: 0px;   
                        }
                    }
                    th:nth-child(10){ // hdrn mint
                        width: 130px;
                        text-align: right;
                        padding-right: 15px;
                        @media (max-width: 767px) {
                            padding-right: 10px;   
                        }
                    }
                    th:nth-child(11){ // future mint
                        width: 75px;
                        text-align: right;
                        @media (max-width: 767px) {
                            padding-right: 5px;   
                        }
                    }
                    th:nth-child(12){ // apy
                        width: 75px;
                        text-align: right;
                        padding-right: 25px;
                    }
                }
            }
        }

        .loader {
            //position: absolute;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100%;
            height: 100px;
            text-align: center;
            img {
                width: 30px;
                height: 30px;
                display:inline;
            }
        }



        .card, .card-body{
            height: auto;
            max-height: 500px;
    
            border: none;
            font-size: 10px;
        }

        tr:nth-child(even) {
            background-color: var(--background-color-1-alt1);
        }
    
        tr {
            text-align: center;
            
            td{
                padding-top:3px;
                padding-bottom: 3px;
            }
            td:nth-child(1){ // chain
                width: 30px;
            }
            td:nth-child(2){ // address
                width: 70px;
            }
            td:nth-child(3){ // type
                width: 70px;
            }
            td:nth-child(4){ // start
                width: 70px;
                font-size: 9px;
                overflow:hidden;
                white-space:nowrap;
            }
            td:nth-child(5){ // end
                width: 70px;
                font-size: 9px;
                overflow:hidden;
                white-space:nowrap;
            }
            td:nth-child(6){ // shares
                width: 75px;
                text-align: right;
            }
            td:nth-child(7){ // value
                width: 75px;
                text-align: right;
                padding-right: 5px;
            }



            td:nth-child(8){ // hex burned
                width: 70px;
                text-align: right;
            }
            td:nth-child(9){ // hex mined
                width: 80px;
                text-align: right;
            }
            td:nth-child(10){ // hdrn mint
                width: 130px;
                text-align: right;
            }
            td:nth-child(11){ // future mint
                width: 75px;
                text-align: right;
            }
            td:nth-child(12){ // apy
                width: 75px;
                text-align: right;
                padding-right: 5px;
            }
        }
    }


    .chart-card{
        small {
            display: inline-block;
            margin-top: -5px;
            font-size: 10px;
        }
    }
    .chart-background {
        background-color: rgba(1,1,1,.2);
    }
}

