@import "../SiteWide/text-styling.scss";
@import "../SiteWide/content-styling.scss";

.left-nav-bar {
    user-select: none;
    display:inline-block;
    box-shadow: inset -10px 0 10px -8px var(--background-color-0);
    color: var(--text-color);
    background: var(--linear-background-0);//var(--background-color-0);
    padding: 5px 10px 5px 10px;
    padding-top: 25px;
    padding-bottom: 25px;

    min-height: 100%;
    transition: left 1s;

    // Fixed to top left
    position: fixed;
    top:0px;


    .filter-network {
        position: absolute;
        width: calc( 100% - 100px );
        top: 22px;
        right: 20px;
        z-index: 6000;
        
        @media (max-width: 767px) {
            /* for mobile devices */
            top: 95px;
            right: 70px;
            width: calc( 100% - 150px );
        }

        .filter-network-note{
            position: absolute;
            color: var(--warning-text-0);
            font-size: 8.5px;
            margin-top: -8.5px;
        }

        select {
            height: 20px;
            background-color: var(--background-color-0-alt1); /* change to the desired background color */
            box-shadow: inset 0 0 2px 2px var(--background-color-3);
            border: none;
            border-radius: 4px;
            color: var(--muted-text-color); /* change to the desired text color */
            font-size: 10px;
            text-align: center;
            padding: 3px;
            width: 100%;
            transition: box-shadow 0.2s, color 0.2s, background-color 0.2s;
            cursor: pointer;
            &:focus {
                outline: none;
                border-color: var(--background-color-3);
                background-color: var(--background-color-4-alt1);
                box-shadow: inset 0 0 3px 2px var(--background-color-4);
            }
            &:hover {
                background-color: rgba(var(--background-color-4), 0.3);
                box-shadow: inset 0 0 3px 2px var(--background-color-4);
                color: var(--text-color);
            }
        }
        
        option {
            background-color: var(--background-color-0); /* change to the desired background color */
            color: var(--muted-text-color); /* change to the desired text color */
            text-align: left;
            //font-size: 12px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    
    .wallets-watchlist, .tokens-watchlist {
        position: relative;
    }
    /* Hide on mobile, show on desktop */
    @media (max-width: 767px) {
        /* for mobile devices */
        //transform: translateX(-300px);
        padding-top: 100px;
        padding-bottom: 100px;
        
        &.open-menu {
            //position: absolute;
            z-index: 4000;
            width: 100vw;
            left: 0px;

            height: 100vh;
            overflow-y: auto;
            .menu-bars {
                position: fixed;
                right: 5px;
                top: 76px;
            }
        }
        &.close-menu {
            //position: absolute;
            z-index: 4000;
            width: 100vw;
            left: calc( -100vw );

            .menu-bars {
                position: fixed;
                right: calc( 100vw - 50px );
                top: 76px;
                // right: -50px;
                // top: 76px;
            }
        }

        scrollbar-width: var(--scroll-bar-width);
        scrollbar-color: var(--scroll-bar-color);
        /* Set the width of the scrollbar */
        &::-webkit-scrollbar {
            background-color: var(--scroll-bar-color);
            width: var(--scroll-bar-width);
        }
    
        /* Set the background color of the scrollbar track */
        &::-webkit-scrollbar-track {
            background-color: var(--scroll-bar-track);
        }
        
        /* Set the color and border radius of the scrollbar thumb */
        &::-webkit-scrollbar-thumb {
            background-color: var(--scroll-bar-thumb-inner);//calc( var(--scroll-bar-thumb) * 1.3);
            border-radius: 5px;
        }
        &::-moz-scrollbar-thumb {
            background-color: var(--scroll-bar-thumb-inner);//calc( var(--scroll-bar-thumb) * 1.3);
            border-radius: 5px;
        }
    
        /* Set the color of the scrollbar thumb on hover */
        &::-webkit-scrollbar-thumb:hover, ::-moz-scrollbar-thumb:hover {
            background-color: var(--scroll-bar-thumb-hover);
        }
        
        /* Set the border radius of the top and bottom of the scrollbar track */
        &::-webkit-scrollbar-track-piece:start, .custom-scrollbar::-webkit-scrollbar-track-piece:end {
            border-radius: 5px;
        }
    }
    
    @media (min-width: 768px) {
    /* for desktop devices */
        //transform: translateX(0px);
        position: relative;
        width: 300px;
    }

    .rename-watchlist {
        position: relative;
    }

    .special-button {
        transition: color 0.3s;
        cursor:pointer;
        &:hover {
            color: var(--text-color-highlighted);
        }
    }
    .settings-button, .settings-button-token {
        position: absolute;
        display: inline-block;
        text-align: center;
        width: 30px;
        right: 4px;
        top: 5px;
        transition: color 0.3s;
        cursor: pointer;
        &:hover {
            color: var(--text-color-highlighted);
        }
    }

    .settings-button-token {
        top: 28px;
    }



    .remove-wallet-button, .remove-token-button{
        position: absolute;
        display: inline-block;
        text-align: center;
        width: 18px;
        height: 16px;
        left: -10px;
        top: 10px;
        font-size: 16px;
        transition: color 0.3s, background-color 0.3s;
        cursor: pointer;
        color: var(--muted-text-color);
        //background-color: rgba(var(--danger-color-1), 0);
        background-color: rgba(red,0.5);
        border-radius: 3px;
        box-shadow: inset 0 0 1px 1px var(--danger-color-0);
        svg {
            padding-bottom: 6px;
        }
        &:hover {
            color: var(--text-color);
            background-color: var(--danger-color-0);
        }
    }
    .remove-token-button {
        left: 5px;
    }
    .token-setting-item {
        padding-left: 13px;
    }

    // only visible on mobile
    .menu-bars {
        padding: 0px 10px 0px 10px;
        cursor: pointer;
        position: absolute;
        z-index: 6500;
        font-size: 30px;
        right: 13px;
        //top: 1px;
        top: 76px;
        transition: right 1s, background-color 0.3s ;
        border: 1px solid var(--background-color-0);
        //background-color: var(--background-color-0-alt0);
        background-color: var(--background-color-2);
        &:hover{
            background-color: var(--background-color-3);        
        }
    }

    





    .card {
        //background: inherit;
    }



    .left-nav-header {
        display: block;
        width: 100%;
        border-bottom: 1px solid var(--text-color);
    }

    .drag-and-drop-area {
        border: none;
        background-color:var(--background-color-0-alt2);
    }
    .drag-and-drop-item{
        overflow: hidden;
        position: relative;
        border: 1px solid var(--background-color-3);
        background-color: var(--background-color-2);
        &:hover{
            background-color: var(--background-color-3);
        }
        &.selected {
            background-color: var(--background-color-4);
        }
        .platform-img {
            pointer-events: none;
            display: inline-block;
            position: absolute;
            left: -60px;
            top: -30px;
            height: 100px!important;
            width: 100px!important;
            z-index: 0;
            &.ETH {
                width: 125px!important;
                height: 125px!important;
            }
            img {
                width: 100%;
                height: 100%;
                filter: grayscale(.8) blur(2px);
                opacity: .4;
            }
        }
    }
    

    input, textarea  {
        background: var(--background-color-0);
        color: var(--text-color-highlighted);
    }



    .ticker-image {
        display: inline-block;
        width: 25px;
        height: 100%;
        overflow: hidden;
        background-color: inherit;
        padding: 0 10px 0px 5px;
        img{
            position: absolute;
            margin-top: -12px;
            margin-left: -2px;
            border-radius: 15px;
            width: 15px;
            height: 15px;
        }
    }

    .ticker-price{
        display: inline-block;
        position: absolute;
        right: 5px;
        margin-top: -5px;
        background: var(--background-color-0);
        border: var(--background-color-2) solid 1px;
        padding: 5px 5px 5px 5px;
        width: 100px;
        font-family:monospace;
        font-size: 10px;
        text-align: center;
        color: var(--ticker-color-green);
    }

    .wallet-balance{
        display: inline-block;
        position: absolute;
        right: 5px;
        margin-top: -5px;
        //background: var(--background-color-0);
        //border: var(--background-color-2) solid 1px;
        padding: 5px 5px 5px 5px;
        width: 120px;
        font-family:monospace;
        font-size: 10px;
        text-align: right;
        color: var(--text-color-highlighted);
    }
    .wallet-balance-token{
        position: absolute;
        left: 0;
        top: 4px;

        img {
            width: 15px;
            height: 15px;
        }   
    }
    .custom-offset-{
        &1 { margin-left: calc( 8px * 1 );}
        &2 { margin-left: calc( 8px * 2 );}
        &3 { margin-left: calc( 8px * 3 );}
        &4 { margin-left: calc( 8px * 4 );}
        &5 { margin-left: calc( 8px * 5 );}
        &hide { display: none; }
    }

    .currency-change-icons {
        padding-top: 10px;
        img {
            display: inline;
            height: 30px;
            width: 30px;
            border-radius: 15px;
        }

        div {
            display: inline-block;
            text-align: center;
            height: 30px;
            width: 30px;
            border-radius: 15px;
            cursor: pointer;
            transition: filter 0.3s;
            filter: contrast(0.3);
            margin-right: 10px;
            &:hover{
                filter: contrast(0.7);
            }
            &.selected-item {
                filter: contrast(1);
                box-shadow: 0 0 5px 5px var(--background-color-5);
                img {
                    box-shadow: 0 0 2px 1px var(--background-color-0);
                }
            }
        }
    }
    
    .do-follow-nomics{
        padding-left: 15px;
        font-size:8px;
    }

    .quicklinks-list {
        padding-top: 25px;
        width: 100%;
        .t-muted {
            padding-top: 10px;
        }
        .nav-link {
            padding: 5px;
            width: 100%;
            border: 1px solid var(--background-color-3);
            background: var(--background-color-2);
            color: var(--text-color);
            text-align: center;
            transition: all 0.3s;
            &:hover {
                cursor: pointer;
                background: var(--background-color-1);
                font-size: 19px;
            }

            a {
                width: 100%;
                height: 100%;
            }
        }
        .nav-link-title {
            color: var(--text-color);
            font-size: 13px;
            width: 100%;
            text-align: left;
        }
        .nav-link-title:not(:first-child){
            padding-top: 10px;
        }
    }
};