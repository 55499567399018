//@import "../SiteWide/text-styling.scss";

.site-header {
    user-select: none;
    height: 75px;
    width: 100%;
    min-width: 100vw;
    background-color: var(--background-color-1);
    position: relative;
    
    .site-header-logo{
        display: none;
        position: absolute;
        left: 20px;
        top: 10px;
        height: 50px;
        width: 50px;
        img {
            height: 100%;
            width: 100%;
        }
    }

    .site-header-text{
        position: absolute;


        padding-left: 0px;
        padding-top: 5px;
        font-size: 40px;
        width: 300px;
        background: var(--logo-gradient);
        //-webkit-linear-gradient(75deg, var(--hex-color-0) 20%, var(--hex-color-1) 60%, var(--hex-color-2) 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-weight: bold;
    }

    .fixed-header {
        position: fixed;
        background-color: var(--fixed-header-color);
        z-index: 100000;
        top:0;
        left: 0;
        box-shadow: 0 5px 10px -5px var(--background-color-0);
    }


    .header-buttons {
        position: absolute;
        width: 150px;
        height: 75px;
        right: 0px;
        //bottom: 19px;
        bottom: 30px;
    }

    .twitter-image, .donate-image, .patreon-image {
        position: absolute;
        bottom: 15px;
        right: 35px;
        height: 20px;
        width: 20px;
        img {
            width: 100%;
            height: 100%;
            margin-top: -9px;
        }
        
        transition: filter 0.2s, transform 0.2s;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
            color: gold;
            cursor: pointer;
            animation: rocking 1s ease-in-out infinite;
        }

        small {
            display: block;
            position: relative;
            margin-top: -5px;
            margin-left: -5px;
            font-size: 10px;
        }
    }

    .donate-image {
        bottom: 15px;
        right: 75px;
        
        svg {
            color: gold;//var(--hex-color-0);
            transform: scale(0.6);
            margin-top: -2px;
            width: 100%;
            height: 100%;
        }

        small {
            display: block;
            position: relative;
            margin-top: -5px;
            margin-left: -7px;
            font-size: 10px;
        }
    }

    .patreon-image {
        bottom: 15px;
        right: 120px;
        img {
            filter: brightness(80%);
        }

        &:hover {
            img{
                filter: brightness(100%);
                margin-top: -8px;
            }
        }
    }

    .donate-message {
        padding: 10px;
        padding-left: 15px;
        font-size: 8px;
        position: absolute;
        bottom: -110px;
        right: 0px;
        font-size: 10px;
        
        height: 90px;
        width: 220px;

        span{
            display: inline-block;
            padding-left: 10px;
            margin-left: -5px;
            padding-bottom: 5px;
        }
        background-color: var(--background-color-3);
        box-shadow: inset 0 0 2px 3px var(--background-color-2);

        transition: transform 0.3s, color 0.3s;
        color: white;
        transform: translateX(0px);
        &.close{
            color: var(--background-color-3);
            transform: translateX(220px);
        }

    }

    .donate-message-opener {
        z-index: 1;
        position: absolute;
        right: 38px;
        bottom: -55px;
        animation: rock-slight 5s infinite;
        animation-delay: 1s;
        color: var(--muted-text-color);
        transition: transform 0.3s, color 0.3s;
        transform: scale(1);
        &:hover {
            color: white;
            transform-origin: center;
            transform: scale(1.5);
            animation: none;
            cursor: pointer;
        }
    }

    .toggle-theme {
        position: absolute;
        top: 75px;
        width: 100%;
        margin-left: -10px;
    }

    /* for mobile devices */
    @media (max-width: 767px) {
        .site-header-text {
            text-align: left;
            padding-left: 15px;
        }

        .header-buttons {
            position: absolute;
            width: 150px;
            height: 75px;
            right: 5px;
            //bottom: 19px;
            bottom: 35px;
        }

        .donate-message-opener{
            bottom: 12px;
            right: 4px;
        }

        .donate-message {
            right: -5px;
        }
        .toggle-theme {
            margin-left: -2px;
        }
    }

    

    select {
        height: 20px;
        background-color: var(--background-color-0-alt1); /* change to the desired background color */
        box-shadow: inset 0 0 2px 2px var(--background-color-3);
        border: none;
        border-radius: 4px;
        color: var(--muted-text-color); /* change to the desired text color */
        font-size: 10px;
        text-align: center;
        padding: 3px;
        width: 100%;
        transition: box-shadow 0.2s, color 0.2s, background-color 0.2s;
        cursor: pointer;
        &:focus {
            outline: none;
            border-color: var(--background-color-3);
            background-color: var(--background-color-4-alt1);
            box-shadow: inset 0 0 3px 2px var(--background-color-4);
        }
        &:hover {
            background-color: rgba(var(--background-color-4), 0.3);
            box-shadow: inset 0 0 3px 2px var(--background-color-4);
            color: var(--text-color);
        }
    }
    
    option {
        background-color: var(--background-color-0); /* change to the desired background color */
        color: var(--muted-text-color); /* change to the desired text color */
        text-align: left;
        //font-size: 12px;
        &:hover {
            cursor: pointer;
        }
    }

    .header-tickers {
        text-align: right;
        position: fixed;
        //left: 200px;
        left: 0px;
        bottom: 0px;
        background: black;
        //width: calc( 100% - 225px );
        width: calc( 100% - 20px );
    }

    
    /* for desktop devices */
    @media (min-width: 768px) {

    }
    
}

@keyframes rock-slight {
    0% {
      transform: rotate(0);
    }
    58% {
      transform: rotate(0);
    }
    60% {
      transform: rotate(25deg);
    }
    63% {
        transform: rotate(-15deg);
    }
    68% {
        transform: rotate(15deg);
    }
    74% {
        transform: rotate(-15deg);
    }
    78% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0);
    }
  }
  
  @keyframes wait {
    0% {
      transform: rotate(2deg);
    }
    100% {
      transform: rotate(2deg);
    }
  }

@keyframes rocking {
    0% {
      transform: rotateZ(-10deg);
    }
    50% {
      transform: rotateZ(10deg);
    }
    100% {
      transform: rotateZ(-10deg);
    }
  }